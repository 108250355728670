<template>
  <div>
    <b-card>
      <b-card-text>
        <div class="row" v-if="hasSerie">
          Esta computadora ya posee una serie.
        </div>
        <div class="row" v-if="!hasSerie">
          <div class="col-md-4">
            <b-form-group label="Serie factura">
              <b-input-group prepend="F" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  v-model="serie.serie_factura"
                  required
                  maxlength="3"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Serie boleta">
              <b-input-group prepend="B" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  v-model="serie.serie_boleta"
                  required
                  maxlength="3"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Serie NC de Factura">
              <b-input-group prepend="F" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  v-model="serie.serie_nota_credito_factura"
                  required
                  maxlength="3"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Serie NC de boleta">
              <b-input-group prepend="B" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  v-model="serie.serie_nota_credito_boleta"
                  required
                  maxlength="3"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Serie de vale">
              <b-input-group prepend="V" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  v-model="serie.serie_vale"
                  required
                  maxlength="3"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-card-text>
      <template #footer>
        <b-button variant="primary" @click="saveSerie" v-if="!hasSerie"
          >Guardar</b-button
        >
      </template>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "ProductCreate",
  data() {
    return {
      hasSerie: true,
      serie: {
        serie_factura: `${this.makeid(3)}`,
        serie_boleta: `${this.makeid(3)}`,
        serie_nota_credito_factura: `${this.makeid(3)}`,
        serie_nota_credito_boleta: `${this.makeid(3)}`,
        serie_vale: `${this.makeid(3)}`
      }
    };
  },
  mounted() {
    this.getSerie();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Serie" },
      { title: "Configurar Serie" }
    ]);
  },
  methods: {
    saveSerie() {
      let serie = {
        serie_factura: `F${this.serie.serie_factura}`,
        serie_boleta: `B${this.serie.serie_factura}`,
        serie_nota_credito_factura: `F${this.serie.serie_nota_credito_factura}`,
        serie_nota_credito_boleta: `B${this.serie.serie_nota_credito_boleta}`,
        serie_vale: `V${this.serie.serie_vale}`,
        identifier: localStorage.pos_key
      };
      ApiService.post("machine-id/", serie).then(res => {
        console.log(res);
      });
    },
    makeid(length) {
      let result = "";
      let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    makeToastVariant(message = "Éxito", variant = "success") {
      this.$bvToast.toast(message, {
        title: variant === "success" ? "Éxito" : "Ooops",
        variant: variant,
        solid: true
      });
    },
    getSerie() {
      // Check if I have a uuid in localStorage
      if (localStorage.getItem("pos_key") === null) {
        localStorage.clear();
        this.$router.push({ name: "login" });
      } else {
        ApiService.get(`machine-id/serie`, `?pos_key=${localStorage.pos_key}`)
          .then(res => {
            this.hasSerie = true;
            console.log(res);
          })
          .catch(res => {
            this.hasSerie = false;
            console.log(res);
          });
      }
    }
  }
};
</script>
